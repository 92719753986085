<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="Banner名称">
                <el-input v-model="search.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="城市">
                <el-select v-model="search.city" placeholder="请选择城市" style="width: 100%">
                  <el-option v-for="item in cityList" :key="item.region_code" :label="item.region_name"
                    :value="item.region_code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Banner状态">
                <el-select v-model="search.state" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="禁用" :value="2"></el-option>
                  <el-option label="正常" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="展示时间">
                <el-date-picker style="width: 70%" v-model="searchtime" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">
              显示搜索
            </el-button>
            <el-button v-if="is_auth('weapphome.banner.isadd')" icon="el-icon-plus" size="medium" type="primary"
              @click="add_page">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div style="height: 140px; width: 463px">
          <el-carousel indicator-position="outside">
            <el-carousel-item v-for="(item, index) in banner" :key="index">
              <div style="
                          width: 100%;
                          height: 100%;
                          display: flex;
                          align-items: center;
                        ">
                <img :src="item.imgurl" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-row>

      <div style="height: 40px"></div>
      <!--列表-->
      <el-table v-loading="loading" :data="tableData" border size="medium">
        <el-table-column prop="orderby" label="排序" width="40"></el-table-column>
        <el-table-column prop="type" label="banner位置" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 'banner'">主页轮播图</span>
            <span v-if="scope.row.type === 'hotspot'">热门精选</span>
            <span v-if="scope.row.type === 'globalfind'">全局约练-发现</span>
            <span v-if="scope.row.type === 'my'">我的</span>
          </template>
        </el-table-column>
        <el-table-column prop="ad_name" label="名称" width="160"></el-table-column>
        <el-table-column prop="photo" label="Banner" width="130">
          <template slot-scope="scope">
            <el-image style="height: 22px; margin-right: 4px" :src="scope.row.imgurl" fit="contain"
              :preview-src-list="[scope.row.imgurl]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.state === -1" type="danger" size="small">删除</el-tag>
            <el-tag v-if="scope.row.btn_state === 1" size="small">正常</el-tag>
            <el-tag v-if="scope.row.btn_state === 2" type="warning" size="small">禁用</el-tag>
            <el-tag v-if="scope.row.btn_state === 3" type="info" size="small">过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="to_url_type" label="跳转类型" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.to_url_type === 0" type="info" size="mini">不跳转</el-tag>
            <el-tag v-if="scope.row.to_url_type === 1" effect="dark" type="success" size="mini">网址</el-tag>
            <el-tag v-if="scope.row.to_url_type === 2" effect="dark" size="mini">小程序</el-tag>
            <el-tag v-if="scope.row.to_url_type === 3" effect="dark" size="mini">底部tab页面</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="to_url" label="跳转地址" width="300" show-overflow-tooltip />
        <el-table-column prop="start_time" label="展示时间" width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.start_time }}至{{ scope.row.end_time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="city_name" label="城市" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.city_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="300">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="添加时间" width="180"></el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template slot-scope="scope">
            <el-button v-if="is_auth('weapphome.banner.isdisable') &&
              scope.row.btn_state === 1
              " @click="operation_tip(scope.row.id, scope.row.ad_name, 'isdisable')
    " type="danger" size="mini">禁用
            </el-button>
            <el-button v-if="is_auth('weapphome.banner.isenable') &&
              scope.row.btn_state === 2
              " @click="operation_tip(scope.row.id, scope.row.ad_name, 'isenable')
    " type="primary" size="mini">启用
            </el-button>
            <el-button v-if="is_auth('weapphome.banner.isadd') && scope.row.btn_state === 1
              " @click="editBanner(scope.row)" type="primary" size="mini">编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>

      <!--编辑-->
      <el-dialog v-loading="loading" v-if="is_auth('weapphome.banner.isadd')" title="添加Banner" :visible.sync="editPage"
        width="50%" :close-on-press-escape="false" :close-on-click-modal="false">
        <el-row>
          <el-form size="medium" :label-width="this.env.label_width" :model="info" :rules="dataRule" ref="dataForm"
            @submit.native.prevent @keyup.enter.native="save()">
            <el-col :span="24">
              <el-form-item label="排序" prop="orderby">
                <el-input v-model="info.orderby" type="number" :min="1" :max="99999"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="名称" prop="ad_name">
                <el-input v-model="info.ad_name" maxlength="10"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传banner" prop="imgurl">
                <Qnupload v-model="info.imgurl" :sum="1" :compress="false" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="城市" prop="city">
                <el-select v-model="info.city" filterable placeholder="请选择城市" widht="100%">
                  <el-option v-for="item in cityList" :key="item.region_code" :label="item.region_name"
                    :value="item.region_code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="banner位置" prop="type">
                <el-select v-model="info.type" placeholder="请选择页面" widht="100%" @change="handleChange()">
                  <el-option label="主页轮播图" value="banner"></el-option>
                  <el-option label="热门精选" value="hotspot"></el-option>
                  <!-- <el-option label="全局约练-发现" value="globalfind"></el-option> -->
                  <el-option label="广场-推荐" value="square"></el-option>
                  <el-option label="我的" value="my"></el-option>
                </el-select>
                <el-select v-if="info.type === 'banner' || info.type === 'square'" filterable
                  v-model="info.motion_category_two" placeholder="请选择页tab" widht="100%">
                  <el-option v-if="info.type === 'banner' && motiontagList.length > 0" label="推荐"
                    :value="99999"></el-option>
                  <el-option v-if="info.type === 'square' && motiontagList.length > 0" label="热门"
                    :value="99998"></el-option>
                  <el-option v-for="(item, index) in motiontagList" :key="index" :label="item.name"
                    :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="跳转类型" prop="to_url_type">
                <el-radio v-model="info.to_url_type" :label="0">不跳转</el-radio>
                <el-radio v-model="info.to_url_type" :label="1">网址</el-radio>
                <el-radio v-model="info.to_url_type" :label="2">内部页面</el-radio>
                <el-radio v-model="info.to_url_type" :label="3">底部tab页面</el-radio>
                <el-radio v-model="info.to_url_type" :label="4">图片</el-radio>
                <el-radio v-model="info.to_url_type" :label="5">外部小程序</el-radio>
              </el-form-item>
            </el-col>
            <el-col v-if="info.type === 'globalfind'" :span="24">
              <el-form-item label="海报文案设置">
                <el-input style="display: inline-block; width: 30%" v-model="keyword_one" maxlength="8"
                  placeholder="最多设置8个字符"></el-input>
                <el-input style="display: inline-block; width: 30%" v-model="keyword_two" maxlength="4"
                  placeholder="最多设置4个字符"></el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="info.to_url_type !== 0" :span="24">
              <template v-if="info.to_url_type === 5">
                <el-form-item label="小程序信息" prop="to_url">
                  <el-input v-model="info.to_url" maxlength="500"></el-input>
                  <span style="font-size:12px;color:#fc142f">格式：appid,小程序路径参数，如 wxe09c719dec79ec9f,/pages/home/index/index?type=1</span>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item label="跳转地址" prop="to_url" v-if="info.to_url_type !== 4">
                  <el-input v-model="info.to_url" maxlength="255"></el-input>
                </el-form-item>
                <el-form-item label="上传图片" prop="to_url" v-else>
                  <Qnupload v-model="info.to_url" :sum="1" :compress="false" />
                </el-form-item>
              </template>
            </el-col>
            <el-col :span="24">
              <el-form-item label="展示时间" prop="exhibition_time">
                <el-date-picker v-model="info.exhibition_time" type="datetimerange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input v-model="info.remark" maxlength="10"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button size="medium" type="primary" @click="save">添 加</el-button>
          <el-button size="medium" @click="editPage = false">取 消</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Qnupload from "@/components/Qnupload";

export default {
  components: {
    Qnupload,
  },
  data() {
    return {
      page_name: "Banner设置",
      issearch: false,
      loading: true, // 加载状态
      count: 0, // 数据总条数
      tableData: [], // 列表内容
      search: {}, // 搜索内容
      banner: [], // banner内容
      page: 1, // 当前页数
      info: {}, // 编辑数据
      searchtime: null, // 时间条件
      editPage: false,
      keyword_one: "",
      keyword_two: "",
      cityList: [],
      dataRule: {
        orderby: [{ required: true, message: "请填写排序", trigger: "blur" }],
        ad_name: [
          { required: true, message: "banner名称不能为空", trigger: "blur" },
        ],
        imgurl: [
          { required: true, message: "请上传banner图片", trigger: "blur" },
        ],
        city: [{ required: true, message: "请选择城市", trigger: "blur" }],
        to_url_type: [
          { required: true, message: "请选择跳转类型", trigger: "change" },
        ],
        to_url: [
          { required: true, message: "请填写跳转地址", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择banner位置", trigger: "change" },
        ],
        exhibition_time: [
          { required: true, message: "请选择展示时间", trigger: "change" },
        ],
        motion_category_two: [
          { required: true, message: "请选择二级运动品类", trigger: "change" },
        ],
      },
      motiontagList: [],
    };
  },
  // 创建
  created() {
    this.init();
    this.getRegion();
    this.getMotiontagList();
  },
  // 安装
  mounted() { },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {
        name: "",
        state: "",
      };
      this.searchtime = null;
      this.is_search();
    },
    handleChange() { },
    // 获取城市列表
    getRegion() {
      let postdata = {
        api_name: "systemset.cityset.getlist",
        token: this.Tool.get_l_cache("token"),
      };
      Object.assign(postdata);

      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        // if(json.code === 0){}
        this.cityList = json.data;
      });
    },
    // 获取二级运动品类
    getMotiontagList() {
      let postdata = {
        api_name: "category.motiontag.getalltwo",
        token: this.Tool.get_l_cache("token"),
      };
      Object.assign(postdata);

      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        console.log(json);
        if (json.code === 0) {
          this.motiontagList = json.data.list;
        }
      });
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "weapphome.banner.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      // 判断时间条件
      if (this.searchtime !== null) {
        postdata.start_time = this.searchtime[0];
        postdata.end_time = this.searchtime[1];
      }
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.banner = json.data.banner;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.banner = [];
          this.count = 0;
          this.$message.error(json.message);
        }
      });
    },
    // 操作提示
    operation_tip(id = "", ad_name = "", operation = "") {
      let tip = "";
      let options = {};
      if (operation === "isdisable") {
        tip = "禁用【" + ad_name + "】Banner？";
      }
      if (operation === "isenable") {
        tip = "启用【" + ad_name + "】Banner？";
      }

      // 弹出二次确认
      this.$confirm(tip, "确认信息", options)
        .then(() => {
          this.isoperation(id, operation);
        })
        .catch(() => { });
    },
    // 操作
    isoperation(id = "", operation = "") {
      let postdata = {
        api_name: "weapphome.banner." + operation,
        token: this.Tool.get_l_cache("token"),
        id,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.getlist(); // 刷新列表(重新搜索)
            },
          });
        } else {
          this.$message.error(json.message);
        }
      });
    },
    // 添加页面显示
    add_page() {
      this.tag_uuid = "";
      this.info = {
        orderby: "",
        ad_name: "",
        imgurl: "",
        to_url_type: 0,
        to_url: "",
        exhibition_time: "",
        type: "banner",
      };
      this.editPage = true; // 显示页面
    },
    // 保存
    save() {
      const { keyword_two, keyword_one } = this;
      let postdata = {
        api_name: "weapphome.banner.isadd",
        token: this.Tool.get_l_cache("token"),
      };
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          Object.assign(postdata, this.info);
          if (this.info.type === "globalfind") {
            postdata["json_extend"] = {
              keyword_one,
              keyword_two,
            };
          }
          this.loading = true;
          this.Tool.post_data("oss", postdata, (json) => {
            this.loading = false;
            if (json.code === 0) {
              this.editPage = false;
              this.$message({
                message: "保存成功",
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.getlist();
                },
              });
            } else {
              this.$message.error(json.message);
            }
          });
        }
      });
    },
    // 编辑banner
    editBanner(item) {
      const info = {
        exhibition_time: [item.start_time, item.end_time],
        ...item,
      };
      this.editPage = true;
      info['motion_category_two'] = item['motion_category_two']['id'];
      this.info = info;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
  white-space: nowrap;
  /*overflow: initial;*/
}

>>>.el-carousel__container {
  height: 140px;
}

>>>.el-carousel__container img {
  width: 100%;
}
</style>
